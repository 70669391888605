import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { UPLOADING_PHOTOS } from 'mk/autogenerated/translations/SubmitButton.d5ad438725f87b2aa350e8830f692869'
import { Btn, BtnType } from 'mk2/components/Btn';
import { PhotoUploadStatus } from 'mk2/helpers/form.reducers';
import { AppState } from 'mk2/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, isDirty as formIsDirty, isSubmitting as formIsSubmitting, submit } from 'redux-form';

const fasSpinnerSpin: FontAwesomeIconProps = {icon: faSpinner, spin: true};

interface OwnProps {
    className?: string;
    formName: string;
    label: string;
    customLabelWhenUploadingPhotos?: string;
    icon?: string | IconLookup | FontAwesomeIconProps;
    img?: React.ReactNode;
    canBeVisuallyDisabled?: boolean;
    btnType?: BtnType;
    disabled?: boolean;
}

interface StateProps {
    // requestUser: UserEntity;
    // requestUserPermissions: string[];
    isDirty: boolean;
    isSubmitting: boolean;
    isUploadingPhotos: boolean;
}

interface DispatchProps {
    onSubmit();
}

class SubmitButtonComponent extends React.Component<OwnProps & StateProps & DispatchProps> {

    public render() {
        const {
            btnType, canBeVisuallyDisabled, className, customLabelWhenUploadingPhotos, icon, img,
            isSubmitting, isUploadingPhotos, label, disabled,
        } = this.props;

        const labelWhenUploadingPhotos = customLabelWhenUploadingPhotos !== undefined
            ? customLabelWhenUploadingPhotos
            : UPLOADING_PHOTOS + '...';

        return (
            <Btn
                className={className}
                type={btnType || BtnType.Blue}
                label={isUploadingPhotos ? labelWhenUploadingPhotos : label}
                icon={isUploadingPhotos ? fasSpinnerSpin : icon}
                img={isUploadingPhotos ? null : img}
                onClick={this.onSubmit}
                hasSpinner={isSubmitting}
                disabled={isUploadingPhotos || disabled}
                canBeVisuallyDisabled={canBeVisuallyDisabled}
                submitFormBtn
            />
        );
    }

    private onSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Do not bubble up
        event.stopPropagation();
        this.props.onSubmit();
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { formName } = ownProps;

    const selector = formValueSelector(formName);
    const currentUploads = selector(state, 'photos') as any[] || [];
    const isFormUploadingPhotos: boolean = currentUploads ? currentUploads.findIndex((u) => u.status === PhotoUploadStatus.PENDING) >= 0 : false;

    return {
        // requestUser: getRequestUser(state),
        // requestUserPermissions: getRequestPermissions(state),
        isDirty: formIsDirty(formName)(state),
        isSubmitting: formIsSubmitting(formName)(state),
        isUploadingPhotos: isFormUploadingPhotos,
    };
}

function mapDispatchToProps(dispatch, ownProps: OwnProps) {
    const { formName } = ownProps;

    return {
        onSubmit: () => dispatch(submit(formName)),
    };
}

export const SubmitButton = connect(mapStateToProps, mapDispatchToProps)(SubmitButtonComponent);
