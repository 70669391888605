import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { FormControl } from 'mk2/components/forms/FormControl';
import React from 'react';
import { EventOrValueHandler, Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './SelectField.mscss';

export interface Option {
    value: string | number;
    label: string;
    isPlaceholder?: boolean;
}

interface SelectInputPublicProps {
    options: Option[];
    disabled?: boolean;
}

type SelectInputProps = SelectInputPublicProps & {
    // CeMi: nepridavajte className do FormFieldov. Rozne varianty UI nech sa
    // menia cez props. Tak su zname vsetky varianty ktore treba testovat a
    // podporovat
    //
    // Opakovane sposobuje bugy, ze ked sa css-ko prebije zhora a potom sa urobi
    // pimpup formfieldov, ostanu niektore formy rozbite. Napr. poposuvana pozicia
    // labelov, ikoniek, atd.
    // className?: string;
    hasArrow?: boolean;
    input: WrappedFieldInputProps;
    transparent?: boolean;
};

export const hasSelectedPlaceholder = (value: string, options: Option[]) =>
    options && options.length && options.findIndex((o) => o.isPlaceholder && isSameValue(o.value, value)) >= 0;

export const valueStr = (value: string | number) =>
    value === null ? '' : `${value}`;

export const isSameValue = (value1: string | number, value2: string | number) => (
    ((value1 === null || value1 === '') && (value2 === null || value2 === '')) ||
    `${value1}` === `${value2}`
);

// TODO: Consider react-select, react-virtualized-select or SelectField from material-ui instead
class SelectInput extends React.Component<SelectInputProps> {

    public static defaultProps = {
        hasArrow: true,
    };

    public render() {
        const { disabled, input, options, hasArrow, transparent } = this.props;

        const selectedPlaceholder = hasSelectedPlaceholder(input.value, options);

        return (
            <div className={styles.SelectInput}>
                <select
                    id={input.name}
                    className={cx(
                        styles.SelectInput__select,
                        selectedPlaceholder && styles['SelectInput__select--selectedPlaceholder'],
                        !!disabled && styles['SelectInput__select--disabled'],
                    )}
                    disabled={!!disabled}
                    {...input}
                >
                    {options.map((o) => (
                        <option value={valueStr(o.value)} key={valueStr(o.value)}>
                            {o.label}
                        </option>
                    ))}
                </select>

                {hasArrow && !disabled && (
                    <div className={cx(styles.SelectInput__icon, transparent && styles['SelectInput--transparent'])}>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                )}
            </div>
        );
    }
}

interface SelectControlPublicProps {
    label?: string;
    bottomBorder?: boolean;
    marginLeft?: boolean;
    transparent?: boolean;
    minimizedLabel?: boolean;
    isAdminUI?: boolean;
}

type SelectControlProps = SelectInputPublicProps & SelectControlPublicProps & WrappedFieldProps;

const SelectControl: React.StatelessComponent<SelectControlProps> = ({
    input, meta, label,          // WrappedFieldProps
    marginLeft, minimizedLabel, transparent, bottomBorder, isAdminUI, // SelectControlPublicProps
    ...selectInputProps          // SelectInputPublicProps
}) => {
    // ak sa ma label prekryvat s textom defaultnej option, tak minimalizuj label
    minimizedLabel = minimizedLabel || (
        // mam label
        !!label &&
        // text prvej option nie je prazdny
        !!selectInputProps.options && selectInputProps.options.length > 0 && !!selectInputProps.options[0].label
    );

    return (
        <FormControl
            marginLeft={marginLeft}
            transparent={transparent}
            minimizedLabel={minimizedLabel}
            label={label}
            input={input}
            meta={meta}
            bottomBorder={bottomBorder}
            isAdminUI={isAdminUI}
        >
            <SelectInput {...selectInputProps} input={input} transparent={transparent}/>
        </FormControl>
    );
};

type SelectFieldProps = SelectInputPublicProps & SelectControlPublicProps & {
    // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
    name: string;
    onChange?: EventOrValueHandler<React.ChangeEvent<any>>;
};

export class SelectField extends React.Component<SelectFieldProps> {
    public render() {
        return (
            <Field
                {...this.props}
                component={SelectControl}
                normalize={this.handleNormalize}
            />
        );
    }

    private handleNormalize = (value) => {
        const { options } = this.props;

        if (!options || !options.length) {
            return value;
        }

        // <select> ma hodnoty iba ako string. Najdi preto Option, ktora je vybrata
        // a vrat jej value (moze to byt napr. cislo)
        const selected: Option  = options.find((o) => isSameValue(o.value, value));
        return selected ? selected.value : value;
    };
}
