import cx from 'classnames';
import range from 'lodash-es/range';
import { DAY, MONTH, YEAR, YOU_DID_NOT_ENTER_VALID_DATE } from 'mk/autogenerated/translations/DateSelectField.950f5f2cc49db3c7990a390278cfd3d8'
import { FormControl } from 'mk2/components/forms/FormControl';
import { hasSelectedPlaceholder, Option } from 'mk2/components/forms/SelectField';
import { translateMonth } from 'mk2/helpers/timeformat';
import React from 'react';
import { Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './DateSelectField.mscss';

interface DateSelectInputPublicProps {
    // rozsah pre vyber roku ej [yearFrom, ..., yearTo] (vratane)
    yearFrom?: number;  // including the 'yearFrom'
    yearTo?: number;
}

type DateSelectInputProps = DateSelectInputPublicProps  & {
    hasLabel: boolean;
    input: WrappedFieldInputProps;
};

const VALUE_RE = /([\d]*)-0?([\d]*)-0?([\d]*)/;

export class DateSelectInput extends React.Component<DateSelectInputProps> {

    public static defaultProps = {
        yearFrom: new Date().getFullYear() - 10,
        yearTo: new Date().getFullYear() + 10,
    };

    private dayInputRef: React.RefObject<HTMLSelectElement>;
    private monthInputRef: React.RefObject<HTMLSelectElement>;
    private yearInputRef: React.RefObject<HTMLSelectElement>;

    private DAY_OPTIONS: Option[];
    private MONTH_OPTIONS: Option[];
    private YEAR_OPTIONS: Option[];

    constructor(props: DateSelectInputProps) {
        super(props);
        this.dayInputRef = React.createRef();
        this.monthInputRef = React.createRef();
        this.yearInputRef = React.createRef();

        this.DAY_OPTIONS = [
            {value: '', label: DAY, isPlaceholder: true},
            ...range(31).map((o) => ({value: `${o + 1}`, label: `${o + 1}.`})),
        ];
        this.MONTH_OPTIONS = [
            {value: '', label: MONTH, isPlaceholder: true},
            ...range(12).map((o) => ({value: `${o + 1}`, label: `${translateMonth(o)}`})),
        ];

        const { yearFrom, yearTo } = props;
        this.YEAR_OPTIONS = [
            {value: '', label: YEAR, isPlaceholder: true},
            ...range(yearTo - yearFrom + 1).map((o) => ({value: `${o + yearFrom}`, label: `${o + yearFrom}`})),
        ];
    }

    public render() {
        const { input, hasLabel } = this.props;

        const m = (input.value || '').match(VALUE_RE);
        const [_, valueY, valueM, valueD] = m ? m : [null, '', '', ''];

        const selectedDayPlaceholder = hasSelectedPlaceholder(valueD, this.DAY_OPTIONS);
        const selectedMonthPlaceholder = hasSelectedPlaceholder(valueM, this.MONTH_OPTIONS);
        const selectedYearPlaceholder = hasSelectedPlaceholder(valueY, this.YEAR_OPTIONS);

        return (
            <div className={styles.DateSelectInput}>
                <select
                    id={`${input.name}`/* pre label-for */}
                    className={cx(
                        styles.DateSelectInput__select,
                        styles.DateSelectInput__selectDay,
                        !hasLabel && styles['DateSelectInput__select--noLabel'],
                        selectedDayPlaceholder && styles['DateSelectInput__select--selectedPlaceholder'],
                    )}
                    {...input}
                    name={`${input.name}_day`}
                    value={valueD}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    ref={this.dayInputRef}
                >
                    {this.DAY_OPTIONS.map((o) => <option value={o.value} key={o.value}>{o.label}</option>)}
                </select>

                <select
                    className={cx(
                        styles.DateSelectInput__select,
                        styles.DateSelectInput__selectMonth,
                        !hasLabel && styles['DateSelectInput__select--noLabel'],
                        selectedMonthPlaceholder && styles['DateSelectInput__select--selectedPlaceholder'],
                    )}
                    {...input}
                    name={`${input.name}_month`}
                    value={valueM}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    ref={this.monthInputRef}
                >
                    {this.MONTH_OPTIONS.map((o) => <option value={o.value} key={o.value}>{o.label}</option>)}
                </select>

                <select
                    className={cx(
                        styles.DateSelectInput__select,
                        styles.DateSelectInput__selectYear,
                        !hasLabel && styles['DateSelectInput__select--noLabel'],
                        selectedYearPlaceholder && styles['DateSelectInput__select--selectedPlaceholder'],
                    )}
                    {...input}
                    name={`${input.name}_year`}
                    value={valueY}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    ref={this.yearInputRef}
                >
                    {this.YEAR_OPTIONS.map((o) => <option value={o.value} key={o.value}>{o.label}</option>)}
                </select>
            </div>
        );
    }

    public getValue(): string {
        const dayElem = this.dayInputRef.current;
        const monthElem = this.monthInputRef.current;
        const yearElem = this.yearInputRef.current;

        const d = dayElem.options[dayElem.selectedIndex].value;
        const m = monthElem.options[monthElem.selectedIndex].value;
        const y = yearElem.options[yearElem.selectedIndex].value;

        return (d || m || y)
            ? `${y}-${m ? m.toString().padStart(2, '0') : ''}-${d ? d.toString().padStart(2, '0') : ''}`
            : null;
    }

    private handleOnChange = (event) => {
        const { input } = this.props;

        const v = this.getValue();
        input.onChange(v);
    };

    private handleOnBlur = (event) => {
        const { input } = this.props;

        const v = this.getValue();
        input.onBlur(v);
    };
}

interface DateSelectControlPublicProps {
    label?: string;
    marginLeft?: boolean;
    transparent?: boolean;
}

type DateSelectControlProps = DateSelectInputPublicProps & DateSelectControlPublicProps & WrappedFieldProps & {
    fieldName: string;
};

const DateSelectControl: React.StatelessComponent<DateSelectControlProps> = ({
    input, meta, label,         // WrappedFieldProps
    marginLeft, transparent,    // DateSelectControlPublicProps
    ...dateSelectInputProps     // DateSelectInputPublicProps
}) => {
    return (
        <FormControl
            marginLeft={marginLeft}
            transparent={transparent}
            input={input}
            label={label}
            minimizedLabel
            meta={meta}
        >
            <DateSelectInput
                {...dateSelectInputProps}
                input={input}
                hasLabel={!!label}
            />
        </FormControl>
    );
};

export function validateISODate(value: string): string {
    const m = (value || '').match(VALUE_RE);
    const [_, valueY, valueM, valueD] = m ? m : [null, '', '', ''];
    if (!valueY || !valueM || !valueD) {
        // este nie je zadany kompletny datum
        return;
    }

    const timestamp: number = Date.parse(value);
    if (isNaN(timestamp)) {
        return YOU_DID_NOT_ENTER_VALID_DATE;
    }

    // otestuj ze nie je zadany neexistujuci datum, napr. 31.9.2018
    if (value !== new Date(timestamp).toISOString().substring(0, 10)) {
        return YOU_DID_NOT_ENTER_VALID_DATE;
    }
}

type DateSelectFieldProps = DateSelectInputPublicProps & DateSelectControlPublicProps & {
    // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
    name: string;
    warn?: (value: string) => string;
};

export const DateSelectField: React.StatelessComponent<DateSelectFieldProps> = ({warn, ...props}) => (
    <Field {...props} component={DateSelectControl as any} warn={warn || validateISODate}/>
);
