import cx from 'classnames';
import { FormControl } from 'mk2/components/forms/FormControl';
import { autoFocusElement, normalizeToInt, AutoFocus } from 'mk2/helpers/form';
import React, { FocusEvent } from 'react';
import { EventWithDataHandler, Field, Normalizer, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './InputField.mscss';

type TextInputType = 'text' | 'radio' | 'date' | 'email' | 'number' | 'tel' | 'url' | 'datetime-local';

interface TextInputPublicProps {
    type: TextInputType;
    autoFocus?: AutoFocus;
    autoCorrect?: string;
    // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Preventing_autofilling_with_autocompletenew-password
    autoComplete?: 'new-password';
    autoCapitalize?: string;
    spellCheck?: boolean;
    maxLength?: number;
    placeholder?: string;
    disabled?: boolean;
    min?: string;
    max?: string;
    hasLargeFont?: boolean;
}

type TextInputOwnProps = TextInputPublicProps & {
    input: WrappedFieldInputProps;

    onAutofill?();
};

class TextInput extends React.Component<TextInputOwnProps> {

    private inputElem: HTMLInputElement;

    private autofillTriggered: () => void;

    constructor(props) {
            super(props);
            this.autofillTriggered = () => { this.labelUpdate(); };
        }

    public render() {
        const {
            input, type, autoFocus, autoCorrect, autoCapitalize, spellCheck,
            maxLength, placeholder, disabled, min, max, autoComplete, hasLargeFont,
        } = this.props;

        return (
            <input
                id={input.name}
                className={cx(
                    styles.TextInput,
                    !!disabled && styles['TextInput--disabled'],
                    hasLargeFont && styles['TextInput--hasLargeFont'],
                )}
                type={type}
                maxLength={maxLength}
                autoCorrect={autoCorrect}
                autoComplete={autoComplete}
                autoCapitalize={autoCapitalize}
                autoFocus={!!autoFocus}
                spellCheck={spellCheck}
                placeholder={placeholder}
                disabled={!!disabled}
                min={min}
                max={max}

                {...input}
                ref={this.handleInputRef}
            />
        );
    }

    public componentDidMount() {
        autoFocusElement(this.inputElem, this.props.autoFocus, this.props.input.value);
        window.addEventListener('transitionend', this.autofillTriggered);
    }

    public componentWillUnmount() {
        window.removeEventListener('transitionend', this.autofillTriggered);
    }

    private handleInputRef = (ref: HTMLInputElement) => {
        this.inputElem = ref;
    };

    private labelUpdate() {
        if (this.props.onAutofill) {
            this.props.onAutofill();
        }
    }
}

interface TextControlPublicProps {
    // CeMi: nepridavajte className do FormFieldov. Rozne varianty UI nech sa
    // menia cez props. Tak su zname vsetky varianty ktore treba testovat a
    // podporovat
    //
    // Opakovane sposobuje bugy, ze ked sa css-ko prebije zhora a potom sa urobi
    // pimpup formfieldov, ostanu niektore formy rozbite. Napr. poposuvana pozicia
    // labelov, ikoniek, atd.
    // className?: string;
    label?: string;
    minimizedLabel?: boolean;
    marginLeft?: boolean;
    bottomBorder?: boolean;
    transparent?: boolean;
    isAdminUI?: boolean;

    onAutofill?();
}

type TextControlProps = TextInputPublicProps & TextControlPublicProps & WrappedFieldProps;

const TextControl: React.StatelessComponent<TextControlProps> = ({
    // GroupProps
    minimizedLabel, marginLeft, bottomBorder, transparent, onAutofill, isAdminUI,
    // WrappedFieldProps
    input, meta, label,
    // InputProps
    ...textInputProps
}) => (
    <FormControl
        input={input}
        meta={meta}
        label={label}
        minimizedLabel={
            minimizedLabel
            || textInputProps.type === 'date'
            || textInputProps.type === 'datetime-local'
            || textInputProps.type === 'number'
            || !!textInputProps.placeholder
        }
        marginLeft={marginLeft}
        bottomBorder={bottomBorder}
        transparent={transparent}
        isAdminUI={isAdminUI}
        disabled={textInputProps.disabled}
    >
        <TextInput
            input={input}
            onAutofill={onAutofill}
            {...textInputProps}
        />
    </FormControl>
);

type TextInputFieldProps = TextInputPublicProps & TextControlPublicProps & {
    // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
    name: string;
    normalize?: Normalizer;
    onChange?: EventWithDataHandler<React.ChangeEvent<any>>;
    onBlur?: EventWithDataHandler<FocusEvent<any>>
};

export const InputField: React.StatelessComponent<TextInputFieldProps> = (props) => (
    <Field
        {...props}
        normalize={props.normalize || (props.type === 'number' ? normalizeToInt : null)}
        component={TextControl}
    />
);
