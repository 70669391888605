import {
    FORM_EXIT_ARE_YOU_SURE,
    TOAST_ERRORS_IN_FORM,
} from 'mk/autogenerated/translations/form.da3a79c0cd2d1c25446db9af6f593ea7'
import { Interpolate, OwnProps as InterpolateProps } from 'mk2/components/Interpolate';
import { InvalidCSRF } from 'mk2/components/InvalidCSRF';
import { XHRFormError, XHRPostError } from 'mk2/helpers/api';
import { cacheLast } from 'mk2/helpers/cache';
import { cleanErrorToasts, showErrorToast, showServerErrorToast, showSuccessToast } from 'mk2/helpers/toasts';
import { Logger } from 'mk2/logger';
import { redirectInPWASaga } from 'mk2/services/browserHistory';
import React from 'react';
import { initialize, reset, stopSubmit } from 'redux-form';
import { put } from 'redux-saga/effects';

export type CursorPosition = 'start' | 'end' | 'select';
export type AutoFocus = boolean | CursorPosition;

export interface DjangoFormFieldError {
    code: string;
    message: string;
    messageParams?: { [key: string]: string };
}

export interface DjangoFormErrors {
    [fieldName: string]: DjangoFormFieldError[];
}

export function* handleXHRFormSuccessSaga<TInitialValues = Record<string, any>>(
    reduxFormName: string,
    gotoUrl?: string,
    toastSuccessMessage?: React.ReactNode,
    newInitialValues?: TInitialValues,
) {
    // Stop submit
    yield put(stopSubmit(reduxFormName));

    // Reset form
    yield put(reset(reduxFormName));

    if (newInitialValues) {
        yield put(initialize(reduxFormName, newInitialValues));
    }

    // Display toast message
    yield cleanErrorToasts();
    if (toastSuccessMessage) {
        yield showSuccessToast(toastSuccessMessage);
    }

    if (gotoUrl) {
        yield redirectInPWASaga(gotoUrl);
    }
}

export function* handleXHRFormErrorSaga(reduxFormName: string, error: XHRFormError | XHRPostError, logger: Logger) {
    if (error.errorCode === 'CSRF_CHANGED_ERROR') {
        yield put(stopSubmit(reduxFormName));
        const reload = () => {
            if (!!window.confirm(FORM_EXIT_ARE_YOU_SURE)) {
                location.reload();
            }
        };
        yield showErrorToast(<InvalidCSRF action={reload} />);
    // Bad request - form invalid
    } else if (error.status === 400 && 'errors' in error.response.body) {
        const djFormErrors: DjangoFormErrors = error.response.body.errors;

        const reduxFormErros = {};
        Object.keys(djFormErrors).forEach((fieldName) => {
            const fieldErrors: DjangoFormFieldError[] = djFormErrors[fieldName];

            const formattedMessages: React.ReactChild[] = [];
            fieldErrors.forEach((fieldError) => {
                if (fieldError.messageParams) {
                    const formattedParams: { [name: string]: React.ReactNode } = {};
                    Object.keys(fieldError.messageParams).forEach((name) => {
                        let value: React.ReactChild = fieldError.messageParams[name];
                        if (name === 'url') {
                            value = <a href={value}>{value}</a>;
                        }
                        formattedParams[name] = value;
                    });

                    const props: InterpolateProps = {
                        key: formattedMessages.length,
                        className: 'errormsg',
                        i18nKey: fieldError.message,
                        useDangerouslySetInnerHTML: true,
                        ...formattedParams,
                    };

                    formattedMessages.push(
                        // <Interpolate> generates <span> element with text inside
                        <Interpolate {...props} />,
                    );
                } else {
                    formattedMessages.push(
                        <span
                            key={formattedMessages.length}
                            className="errormsg"
                            dangerouslySetInnerHTML={{ __html: fieldError.message }}
                        />,
                    );
                }
            });

            // error message for one field if span of span:
            // <span class="errors">
            //     <span> error msg1 </span>
            //     <span> error msg2 </span>
            // </span>
            reduxFormErros[fieldName] = <span className="errormsg_list">{...formattedMessages}</span>;
        });

        yield put(stopSubmit(reduxFormName, reduxFormErros));
        yield showErrorToast(TOAST_ERRORS_IN_FORM);
    } else if (error.status === 403 && 'error' in error.response?.body) {
        yield put(stopSubmit(reduxFormName));
        yield showErrorToast(error.response.body.error);
    } else {
        logger.error(error);
        yield put(stopSubmit(reduxFormName));
        yield showServerErrorToast();
    }
}

export function autoFocusElement(
    element: HTMLInputElement | HTMLTextAreaElement,
    autoFocus: boolean | CursorPosition,
    value: string,
) {
    if (autoFocus) {
        element.focus();

        if (
            (autoFocus === 'select' || autoFocus === true) &&
            // The input element's type ('number') does not support selection.
            element.type !== 'number'
        ) {
            element.setSelectionRange(0, value.length);
            element.scrollTop = 0;
        } else if (autoFocus === 'start') {
            element.setSelectionRange(0, 0);
            element.scrollTop = 0;
        } else if (autoFocus === 'end') {
            element.setSelectionRange(value.length, value.length);
            element.scrollTop = 0;
        }
    }
}

export const normalizeToInt = (value) => value && parseInt(value, 10);

export const normalizeToFloat = (value) => value && parseFloat(value);

export const normalizeToDecimal = (value) => value && Math.round(parseFloat(value) * 100) / 100;

const initialValuesCache = cacheLast();

/*
 * This function helps to avoid unnecessary re-renders of react components because of changing
 * initialValues object.
 *
 * In naive case, mapStateToProps() always creates new object for initialValues, e.g.
 *
 * return {
 *     .... some other props
 *     initialValues: {
 *        field1: 'value1'
 *        field2: 'value2'
 * }
 *
 * so we cache the last initialValues object to avoid re-renders.
 */
export function cachedInitialValues<FormData>(formName, initialValues: FormData, ...cacheKeys): FormData {
    return initialValuesCache(initialValues, formName, ...cacheKeys);
}
