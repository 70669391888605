import React from 'react';

interface OwnProps {}

type Props = OwnProps & React.FormHTMLAttributes<HTMLFormElement>;

export const Form = React.forwardRef<HTMLFormElement, Props>((
    { action, method, ...rest },
    ref,
) => {
    // tslint:disable-next-line:jsx-ban-elements
    return <form ref={ref} action={action || '#'} method={method || 'post'} {...rest} />;
});

Form.displayName = 'Form';
